import React, {Component} from "react";

import 'react-toastify/dist/ReactToastify.css';
import {
    apiGetGlobalSeasonConfig,
    apiGetLeaderboard,
    apiGetUserInfoMany,
} from "./api/requests";
import {Loading} from "./common/utility/loading";
import {delay} from "./common/utility/delay";
import CountdownLabel from "./common/countdownLabel";
import IconTitleValue from "./common/iconTitleValue";
import heroes from "./heroes.json";

class SeasonTopPage extends Component {
    constructor(props) {
        super(props);

        const userId = localStorage.getItem('userId');

        this.state = {
            loading: true,
            userId: userId,
            noSeason: false,
            isHero: this.props.search.get("is_hero")
        }
        this.loadSeasonTop = this.loadSeasonTop.bind(this);
    }

    componentDidMount() {
        this.loadSeasonTop()
    }

    async loadSeasonTop() {
        await apiGetGlobalSeasonConfig({

        }).then(async (response) => {
            this.setState({
                config: response.data.grand_season.config,

            })
            let isHero = true
            if (this.state.isHero === undefined || this.state.isHero === null) {
                isHero = false
            }
            await apiGetLeaderboard(
                1, 50, isHero
            ).then(async (response) => {
                console.log(response)
                await delay(1000);

                let players = response.leaderboard.users;
                const ids = players.map(x => {
                    return x.uid
                });

                let infos
                if(this.state.isHero !== null) {
                    const heroesOn = heroes.filter(hero => hero.playable );
                    infos = heroesOn.map((x, idx) => { return {uid: x.id, username: x.heroName, avatar: x.imageUrl} });
                    infos = {users: infos}
                } else {
                    infos = await apiGetUserInfoMany(ids);
                }

                players = players.map((x, idx) => {
                    return {...x, ...infos.users.find(y => y.uid == x.uid)}
                });


                this.setState({
                    players: players,
                    //grand_season : response.data.grand_season,
                    //seasons: response.data.seasons,
                    loading: false,
                })
            }).catch(e => {
                window.alerts.alert("internal error. please try again later")
            })
        }).catch(e => {
            window.alerts.alert("We are currently doing an update, please try refreshing in 5 minutes!")
            this.setState({
                noSeason: true,
                loading: false,
            })
        })
    }

    render() {
            if(this.state.noSeason === true) {
                return <div className={"pt-4 d-flex flex-column align-content-center table-responsive text-light"}>
                    <div className="panel-row width100" style={{justifyContent: "space-between"}}>
                        <h3 className={"notransform-h3"}>We are currently doing an update, please try refreshing in 5 minutes!</h3>
                    </div>
                </div>
            }
            if(this.state.loading === true) {
                return <div className={"pt-4 d-flex flex-column align-content-center table-responsive text-light"}>
                    <div className="panel-row width100" style={{justifyContent: "space-between"}}>
                        <h1 className={"notransform-h1"}>Current Season Top 50</h1>
                    </div>
                    <Loading></Loading>
                    <div>
                        <p>Season rewards:</p>
                        <ul>
                            <li>1st place - Force Prime NFT and 350 STRK tokens</li>
                            <li>2nd place - 150 STRK tokens</li>
                            <li>3rd place - 100 STRK tokens</li>
                            <li>4th and 5th places - 50 STRK tokens</li>
                            <li>15 random wallets having more then 250 Season Points will each receive 20 STRK
                                tokens
                            </li>
                        </ul>

                        <p>Anonymous logins will not participate in the reward distribution!</p>

                        <p>You can change your nick on the leaderboard and start a new game in the PROFILE tab.</p>
                    </div>
                </div>

            }

        let otherTopLabel = "Heroes leaderboard"
        let otherTopLink = "/leaderboard?is_hero=1"
        let playerField = "Player"
        if (this.state.isHero !== null) {
            otherTopLabel = "Player leaderboard"
            otherTopLink = "/leaderboard"
            playerField = "Hero"
        }

        return (
            <>
                <div className={"pt-4 d-flex flex-column align-content-center table-responsive text-light"}>
                    <div className="d-flex flex-row align-items-center">
                        <h1 className={"notransform-h1"}>Current Season Top 50</h1>
                        <a href={otherTopLink} className={"ms-3 fp-btn-bg-small"}>{otherTopLabel}</a>
                    </div>
                    <div className="pt-3 pb-3">
                        <div className="d-flex ">
                            <IconTitleValue
                                icon="prizePool.svg"
                                title="Reward pool">
                                {this.state.config.prize}
                            </IconTitleValue>
                            <IconTitleValue
                                icon="seasonEnd.svg"
                                title="Season ends in">
                                <CountdownLabel targetDate={new Date(this.state.config.date_end)}/>
                            </IconTitleValue>
                        </div>
                    </div>
                    <div>
                        <p>Season rewards:</p>
                        <ul>
                            <ul>
                                <li>1st place - Force Prime NFT and 300 STRK tokens</li>
                                <li>2nd place - 200 STRK tokens</li>
                                <li>3rd place - 100 STRK tokens</li>
                            </ul>
                        </ul>

                        <p>Anonymous logins will not participate in the reward distribution!</p>

                        <p>You can change your nick on the leaderboard and start a new game in the PROFILE tab.</p>
                    </div>
                    <table role="table" className="leaderboard-table" style={{minWidth: "0px"}}>
                        <thead>
                        <tr role="row" className={"table-row"}>
                            <th colSpan="1" role="columnheader"
                                className={"table-cell"}>
                                <div className="default-tile-header iconTitleTile-title">Place</div>
                            </th>
                            <th colSpan="1" role="columnheader"
                                className={"table-cell-th-username"}>
                                <div className="default-tile-header iconTitleTile-title">{playerField}</div>
                            </th>
                            <th colSpan="1" role="columnheader"
                                className={"table-cell"}>
                                <div className="default-tile-header iconTitleTile-title">Season points</div>
                            </th>
                        </tr>
                        </thead>
                        <tbody role="rowgroup">

                        {this.state.players.map((v, k) => {
                            console.log(v, k, this.state.userId)
                            const selfClass = v.uid === this.state.userId ? " table-self-row" : ""
                            return <>
                                <tr role="row" className={"table-row " + selfClass}>
                                    <td role="cell"
                                        className={"table-cell"}>
                                        <div className="leaderboard-table-place">
                                            <div><span>{v.place}</span></div>
                                        </div>
                                    </td>
                                    <td role="cell"
                                        className={"table-cell-username"}> {v.username}
                                    </td>
                                    <td role="cell"
                                        className={"table-cell"}
                                        style={{alignSelf: "start"}}> {v.points}
                                    </td>
                                </tr>
                            </>
                        })}


                        </tbody>
                    </table>

                </div>
            </>
        )
    }
}

export default SeasonTopPage;
