import React, {Component} from "react";
import {Loading} from "./common/utility/loading";
import {AccountInfoManager} from "./accountInfoManager";
import {delay} from "./common/utility/delay";
import {apiGetGlobalSeasonConfig, apiGetMapConfig, apiGetSelfProfileData} from "./api/requests";
import ModalAuth, {ModalAuthLite} from "./ModalAuth";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {HashLink} from "react-router-hash-link";

class SeasonPassOneLabel extends Component{
    constructor(props) {
        super(props);

        this.state = {
            claimed: this.props.claimed,
        }

        this.claimReward = this.claimReward.bind(this);
    }

    componentDidMount() {

    }

    componentWillUnmount() {
    }

    async claimReward() {
        console.log("claiming reward")
        await this.props.onClaimReward(this.props.claimId).then(async (response) => {
            if(response.success === true) {
                this.setState({
                    claimed: true,
                })
            }
        }).catch(e => {
            console.log(e)
            window.alerts.alert("Claiming reward: internal error")
        })
    }

    render() {

        let color = "primary"
        let progressText = `${Math.round(this.props.value)}%`
        let pointsText = `${this.props.currentPoints} / ${this.props.points}`
        let claimBtn = <></>

        if(this.props.success === true) {
            color = "success";
            progressText = "✓"
            pointsText = ""
        }
        if(this.state.claimed === false && this.props.success === true) {
            claimBtn =
                <>
                    <a className="fp-btn-bg-small mt-1" onClick={() => this.claimReward()}>Claim</a>
                </>
        }
        if(this.props.login === true) {
            claimBtn = <ModalAuth isSmall={true} label={"Claim"} onClickWallet={this.props.onClickWallet} onClickAnonymous={this.props.onClickAnonymous}/>
        }

        return (
            <>
                <div className={"d-flex flex-column align-items-center "}>
                    <Box sx={{position: 'relative', display: 'inline-flex'}}>
                        <CircularProgress color={color} size={80} variant="determinate" value={this.props.value}/>
                        <Box
                            sx={{
                                top: 0,
                                left: 0,
                                bottom: 0,
                                right: 0,
                                position: 'absolute',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            <Typography variant="caption" component="div" color="#ddd" fontSize={26}>
                                {progressText}
                            </Typography>
                        </Box>
                    </Box>
                    <div>{this.props.label}</div>
                    <div>{pointsText}</div>
                    {claimBtn}
                </div>
            </>
        );
    }

}

export default SeasonPassOneLabel;
