import React from "react";
import ModalAuth from "./ModalAuth";
import ModalProfile from "./ModalProfile";
import ModalHowToPlay from "./ModalHowToPlay";
import {Link} from "react-router-dom";


class UpperPanel extends React.Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
    }

    componentWillUnmount() {
    }

    render() {

        let loginDiv = <></>

        if(this.props.account !== null && this.props.account !== undefined && this.props.account !== "") {
            loginDiv =
                <div className={"ms-auto login-element d-flex justify-content-end flex-wrap align-items-center"}>
                    <Link to="/" className="fp-btn-bg">Maps</Link>
                    <Link to="/leaderboard" className="fp-btn-bg">Leaderboard</Link>
                    <ModalHowToPlay label={"How To Play"}/>
                    <ModalProfile label={"Profile"}/>
                    <a className={"fp-btn-bg"} type="button" onClick={this.props.disconnectWallet}>
                        Disconnect
                    </a>
                </div>
        } else {
            loginDiv =
                <div className={"ms-auto login-element d-flex justify-content-end flex-wrap align-items-center"}>
                    <Link to="/" className="fp-btn-bg ">Maps</Link>
                    <Link to="/leaderboard" className="fp-btn-bg">Leaderboard</Link>
                    <ModalHowToPlay label={"How To Play"}/>
                    <ModalAuth label={"Connect"} onClickWallet={this.props.onClickWallet} onClickAnonymous={this.props.onClickAnonymous}/>
                </div>
        }

        //loginDiv = <></>

        const seasonDiv = <div className={"ms-auto login-element d-flex justify-content-end flex-wrap"}>
            <Link to="/seasons" className="default-btn ms-0 ms-xs-3">Seasons</Link>
        </div>

        return (
            <div className="account-panel d-flex flex-md-row flex-column pt-2 pt-sm-2 pt-md-3 pt-xxl-2 pt-xl-2 pt-md-2 ps-2 pe-2 pb-3 pb-sm-0">
                <div className="logo-container d-flex flex-column justify-content-start pb-md-0 pb-3">
                    <div className={""}>
                        <a href={"/"}><img alt={"merge-game"} className={"logo-image"} src={require('./static/logo-NewRocker.png')}/></a>
                    </div>
                </div>

                {loginDiv}
            </div>
        );
    }
}

export default UpperPanel;
