import RequestQueue from "./requestQueue";

const SERVER_URL = String(process.env.REACT_APP_API_URL)

window.fpRequestQueue = new RequestQueue();

function makeApiServerFetchRaw(uri, method, headers, body, priority) {
    return window.fpRequestQueue.sendRequest(SERVER_URL + uri, {
        method: method,
        headers: headers,
        body: body
    }, priority);
}

function makeApiServerFetchFormFromObj(uri, headers, formObj, errorFormat, priority) {
    var formBody = [];
    for (var property in formObj) {
        var encodedKey = encodeURIComponent(property);
        var encodedValue = encodeURIComponent(formObj[property]);
        formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");

    return makeApiServerFetchRaw(uri, "POST",
        {...headers, 'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'},
        formBody,priority);
}

export function authGetDummyUserData(data) {
    var body = {
        user_id: data.user_id,
        access_token: String(process.env.REACT_APP_PUBLIC_KEY),
    };

    return makeApiServerFetchFormFromObj(
        "/forceprime/starknet/account/dummy/create",
        {},
        body
    );
}

export function apiGetSelfProfileData(token) {
    return makeApiServerFetchRaw(
        "/heroes/profile/self",
        "GET",
        new Headers({
            'x-auth-token' : token,
        }),
        null
    )
}

export function apiSetUsername(authToken, username) {
    var body = {
        username: username,
    };

    return makeApiServerFetchFormFromObj(
        "/heroes/profile/set_username",
        {
            'x-auth-token' : authToken,
        },
        body
    )
}

export function apiSetHideTutorial(authToken, hide) {
    var body = {
        hide: hide,
    };

    return makeApiServerFetchFormFromObj(
        "/heroes/profile/set_hide_tutorial",
        {
            'x-auth-token' : authToken,
        },
        body
    )
}

export function apiGetGameReset(token) {
    return makeApiServerFetchRaw(
        "/heroes/game/reset",
        "GET",
        new Headers({
            'x-auth-token' : token,
        }),
        null
    )
}

export function apiGetSeasonDiscordCode(token) {
    return makeApiServerFetchRaw(
        "/heroes/season/user_discord_code",
        "GET",
        new Headers({
            'x-auth-token' : token,
        }),
        null
    )
}

export function apiGetPrizeInfo() {
    return makeApiServerFetchRaw(
        "/heroes/noauth/prize/current_info",
        "GET",
        {},
        null
    )
}

export function apiGetPreviousSeasonInfo(seasonId) {
    var body = {
    };
    return makeApiServerFetchFormFromObj(
        "/heroes/noauth/season/prev?season_id="+seasonId,
        {},
        body
    )
}


export function apiGetLeaderboard(place: int, count: int, isHero: bool) {
    let isHeroParam = isHero ? "true" : "";
    return makeApiServerFetchRaw(
        `/heroes/noauth/season/leaderboard/get?place=${place}&count=${count}&is_hero=${isHeroParam}`,
        "GET",
        {},
        null
    )
}

export function apiGetUserInfoMany(ids) {
    var body = {
        uids: ids.join(",")
    };
    return makeApiServerFetchFormFromObj(
        `/api/public/profile/info_many`,
        {},
        body
    )
}

export function apiGetGlobalSeasonConfig() {
    var body = {
    };
    return makeApiServerFetchFormFromObj(
        "/heroes/noauth/season/global_config",
        {},
        body
    )
}

export function apiGetMapConfig(authToken, mapId) {
    var body = {
        map_id: mapId,
    };

    return makeApiServerFetchFormFromObj(
        "/heroes/map/config",
        {
            'x-auth-token' : authToken,
        },
        body
    )
}

export function apiGetMapSeasonInfo(mapId, isHero) {
    var body = {
    };

    if(isHero === undefined) {
        isHero = ""
    }

    if(isHero === null) {
        isHero = ""
    }

    return makeApiServerFetchFormFromObj(
        "/heroes/noauth/map/leaderboard/get?map_id="+mapId + "&is_hero=" + isHero,
        {},
        body
    )
}

export function apiGetMapReset(token,mapId) {
    return makeApiServerFetchRaw(
        "/heroes/map/reset?map_id="+mapId,
        "GET",
        new Headers({
            'x-auth-token' : token,
        }),
        null
    )
}

export function apiGetMapStaticSeasonConfig(mapId) {
    var body = {
        map_id: mapId
    };
    return makeApiServerFetchFormFromObj(
        "/heroes/noauth/map/static_config",
        {},
        body
    )
}

export function apiGetMapScores(authToken, grandSeasonId, mapId) {
    var body = {
        map_id: mapId,
        global_season_id: grandSeasonId,
    };

    return makeApiServerFetchFormFromObj(
        "/heroes/map/self_score_get",
        {
            'x-auth-token' : authToken,
        },
        body
    )
}

export function apiGetMapScoresMany(authToken, grandSeasonId, mapIds) {
    var body = {
        map_ids: mapIds.join(","),
        global_season_id: grandSeasonId,
    };

    return makeApiServerFetchFormFromObj(
        "/heroes/map/self_score_get_many",
        {
            'x-auth-token' : authToken,
        },
        body
    )
}

export function apiGetSeasonPass(token,seasonId) {
    return makeApiServerFetchRaw(
        "/heroes/season/pass?global_season_id="+seasonId,
        "GET",
        new Headers({
            'x-auth-token' : token,
        }),
        null
    )
}


export function apiSeasonPassClaim(authToken, seasonId, claimId) {
    var body = {
        global_season_id: seasonId,
        claim_id: claimId,
    };

    return makeApiServerFetchFormFromObj(
        "/heroes/season/pass_claim",
        {
            'x-auth-token' : authToken,
        },
        body, null, true
    )
}

export function apiSetMapSetHero(authToken, mapId, heroId) {
    var body = {
        map_id :mapId,
        hero_id: heroId,
    };

    return makeApiServerFetchFormFromObj(
        "/heroes/map/set_hero",
        {
            'x-auth-token' : authToken,
        },
        body
    )
}