import Modal from 'react-bootstrap/Modal';
import {useState} from "react";
import EditableLabel from "./common/editableLabel";
import {AccountInfoManager} from "./accountInfoManager";
import {useNavigate} from "react-router-dom";
import {toast} from "react-toastify";
import {delay} from "./common/utility/delay";

function ModalProfile({label}) {
    const alert = window.alerts?.alert;
    const success = window.alerts?.success;
    const loading = window.alerts?.loading;
    const loadingStop = window.alerts?.loadingStop;

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const navigate = useNavigate();

    async function setNewNick(nickname) {

        const id = loading("Please wait...");

        const res = await AccountInfoManager.setNewNick(nickname);
        if (res)
            loadingStop(id, "Nickname changed successfully");
        else
            alert("Internal Server Error. Can't change nickname :(");
        return res;
    }

    async function clickHideTutorial(hide) {
        const id = loading("Please wait...");

        const res = await AccountInfoManager.setHideTutorial(hide);
        if (res) {
            loadingStop(id, "Done");
            await delay(500)
            navigate(0);
        }
        else {
            alert("Internal Server Error. Please refresh the page and try again.");
            await delay(500)
            navigate(0);
        }

        return res;
    }

    function getDiscordCode() {
        if(AccountInfoManager.getUserSeasonDiscordCode().length > 0) {
            return (
                <>
                    <h3 className={"notransform-h3 mt-5 "}>Discord Role Code</h3>
                    <p>Congratulations! To get the HERO role, join our Discord at <a href={"https://discord.gg/8SFzxSgbq3"} target={"_blank"}>https://discord.gg/8SFzxSgbq3</a> and submit the code below using the get-role channel</p>
                    <p><strong>{AccountInfoManager.getUserSeasonDiscordCode()}</strong></p>
                </>
            )
        } else {
            return (
                <>
                    <h3 className={"notransform-h3 mt-5 "}>Discord Role Code</h3>
                    <p>Reach 1,000 Season Points this season and you can claim the role of HERO on our Discord at <a
                        href={"https://discord.gg/8SFzxSgbq3"} target={"_blank"}>https://discord.gg/8SFzxSgbq3</a>.</p>
                    <p>Good luck!</p>
                    <button className={"m-1 default-btn"} onClick={(() => {
                        AccountInfoManager.refresh()
                        setShow(false);
                    })}>reload
                    </button>
                </>
            )
        }
    }

    return (
        <>
            <a className={"fp-btn-bg"} onClick={handleShow}>
                {label}
            </a>
            <Modal show={show} onHide={handleClose} dialogClassName="fp-modal-form">
                <Modal.Body>
                    <div className="d-flex flex-column user-box">
                        <h1 className={"notransform-h1 text-center"}>Profile</h1>
                        <div className={"p-5 d-flex flex-column align-items-center"}>
                            <h3 className={"notransform-h3 "}>Nickname</h3>
                            <EditableLabel valueFunc={AccountInfoManager.getUsername} onSave={setNewNick}/>
                            <h3 className={"notransform-h3 mt-5 "}>Balance</h3>
                            <p>Gems: {AccountInfoManager.fpBalance()}</p>
                            {getDiscordCode()}
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default ModalProfile;
