import React from "react";
import { configuration } from "./config";

import { iconPath } from "./common/paths";

export function Page404()
{
    return (
        <div className="footer ms-2 me-2 pt-5 pb-5 ">
            404
        </div>
    );
}
